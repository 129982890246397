import {IAddress} from "./shared";
import {GmtModel} from "./core";
import {Store} from "../../core";

export interface IRaasClientData {
  readonly title: string
  readonly address: IAddress
  readonly clientId: string
  readonly mail: string
}

export class RaasClient extends GmtModel<IRaasClientData> {
  static jsonApiType = "node--gmt_client"
  static endpoint = "node/gmt_client"

  static attMapping: Readonly<Record<string, keyof IRaasClientData>> = {
    title: "title", field_client_adresse: "address", field_client_id: "clientId", field_client_email: "mail",
  }
  readonly addressDataStore = Store.create<Partial<IAddress>>({})
  readonly addressBaseStore = Store.create<Partial<IAddress>>({})

  constructor() {
    super()
    this.dataStore.listen((state) => state.address, (address) => this.addressDataStore.set(address || {}))
    this.baseStore.listen((state) => state.address, (address) => this.addressBaseStore.set(address || {}))
  }

  nid(): number {
    return this.getAttribute("drupal_internal__nid")
  }

  get displayName(): string {
    return this.getAttribute("title")
  }
}
