import {Store} from "../../core";
import {RaasClient} from "../model";

const CClientStore = Store.create<ReadonlyArray<RaasClient>>([])

let promise: Promise<ReadonlyArray<RaasClient>> | undefined

export function getAllClients(): Promise<ReadonlyArray<RaasClient>> {
  return (
    promise ||
    (promise = RaasClient.get()
      .then(
        (response) => {
          const list = response.getData()
          CClientStore.set(list)
          return list
        },
        (error) => {
          console.error(error)
          promise = undefined
          return []
        }
      ))
  )
}

export function getClientStore(): Store<ReadonlyArray<RaasClient>> {
  void getAllClients()
  return CClientStore
}
