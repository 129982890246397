import { ReactElement, useMemo, useState } from "react"

import Paper from "@mui/material/Paper"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"

import { IErrors, useStore } from "core"
import {
  CCountryCodes,
  CCountryNames,
  CSalutationNames,
  CSalutations,
  GmtWinner,
  IAddress,
  IGmtWinnerData,
} from "server/model"
import { IInlineEditingProps, StoreDatePicker, StoreSimpleSelect, StoreTextField } from "../forms"

export interface IWinnerPanelProps {
  readonly winner: GmtWinner
  readonly doSave?: () => Promise<unknown>
  readonly errors?: IErrors<IGmtWinnerData>
}

export default function WinnerPanel(props: IWinnerPanelProps): ReactElement {
  const { winner, doSave, errors } = props
  const { dataStore, baseStore, addressDataStore, addressBaseStore } = winner
  const [addressErrors] = useState<IErrors<IAddress>>()

  const inlineMode = typeof doSave === "function"
  const inlineProps = useMemo<IInlineEditingProps<Partial<IGmtWinnerData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )
  const addressInlineProps = useMemo<IInlineEditingProps<Partial<IAddress>>>(
    () => (inlineMode ? { baseStore: addressBaseStore, doSave } : {}),
    [inlineMode, addressBaseStore, doSave]
  )

  const isCompleted = useStore(
    baseStore,
    ({ winnerStatus }) => !!winnerStatus && winnerStatus !== "Gezogen" && winnerStatus !== "Benachrichtigt"
  )

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Gewinner
        </Typography>
      </Toolbar>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <StoreSimpleSelect
            {...inlineProps}
            label="Anrede"
            store={dataStore}
            property="salutation"
            list={CSalutations}
            labels={CSalutationNames}
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Mobilnummer"
            store={dataStore}
            errors={errors}
            property="mobileNumber"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...addressInlineProps}
            label="Vorname"
            store={addressDataStore}
            errors={addressErrors}
            property="given_name"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Telefonnummer (alternativ)"
            store={dataStore}
            errors={errors}
            property="phoneNumber"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...addressInlineProps}
            label="Nachname"
            store={addressDataStore}
            errors={addressErrors}
            property="family_name"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="E-Mail"
            store={dataStore}
            errors={errors}
            property="mail"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...addressInlineProps}
            label="Straße und Hausnummer"
            store={addressDataStore}
            errors={addressErrors}
            property="address_line1"
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreDatePicker
            {...inlineProps}
            label="Geburtsdatum"
            store={dataStore}
            errors={errors}
            property="birthday"
            disableFuture
            disabled={isCompleted}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <StoreTextField
              {...addressInlineProps}
              label="Adresse - Zusatzinfo"
              store={addressDataStore}
              errors={addressErrors}
              property="address_line2"
              disabled={isCompleted}
              fullWidth
            />
            <StoreTextField
              {...addressInlineProps}
              label="Postleitzahl"
              store={addressDataStore}
              errors={addressErrors}
              property="postal_code"
              disabled={isCompleted}
              fullWidth
            />
            <StoreTextField
              {...addressInlineProps}
              label="Ort"
              store={addressDataStore}
              errors={addressErrors}
              property="locality"
              disabled={isCompleted}
              fullWidth
            />
            <StoreSimpleSelect
              {...addressInlineProps}
              label="Land"
              store={addressDataStore}
              property="country_code"
              list={CCountryCodes}
              labels={CCountryNames}
              fullWidth
              disabled={isCompleted}
              required
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Notiz"
            store={dataStore}
            errors={errors}
            property="notes"
            multiline
            fullWidth
            rows={8}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
