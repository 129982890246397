import {ISimpleInputProps} from "../forms/core";
import {IInlineEditingProps, useInlineEditing} from "../forms";
import {FilteredTypeByPropType, useStore} from "../../../core";
import {GmtSender} from "../../../server/model";
import {ReactElement, useCallback} from "react";
import {ISelectSenderProps} from "../sender/SelectSender";
import {getClientStore} from "../../../server/logic/fetchClient";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Spec} from "immutability-helper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {FormHelperText} from "@mui/material";


const CLabel = "Mandant"
const CLabelID = "SelectClientLabel"
const CEmptyValueID = "_na_"

export interface IClientSenderProps<T extends object, K extends keyof T>
  extends ISimpleInputProps<T, K>,
    IInlineEditingProps<T> {
  readonly small?: boolean
  readonly required?: boolean
  readonly readOnly?: boolean
}

export default function SelectClient<T extends object, K extends keyof FilteredTypeByPropType<T, GmtSender>>(
  props: ISelectSenderProps<T, K>
): ReactElement {
  const {store, property, errors, small, required, baseStore, doSave, readOnly} = props
  const list = useStore(getClientStore())
  const value = useStore(store, (data) => data[property] as unknown as GmtSender | null | undefined, [property])
  const selectValue = (list.length && value?.getApiId()) || CEmptyValueID
  const {endAdornment, error, isInline} = useInlineEditing("object", store, property, required, baseStore, doSave)
  const finalError = error || (errors && errors[property])

  const onChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value
      const objectValue = value === "CEmptyValueID" ? null : list.find((entry) => entry.getApiId() === value)
      store.update({
        [property]: {$set: objectValue},
      } as Spec<T>)
    },
    [list, property, store]
  )

  return (
    <FormControl fullWidth error={!!finalError} size={small ? "small" : undefined}>
      <InputLabel id={CLabelID} required={required} shrink className={isInline ? "inlineMode" : undefined}>
        {CLabel}
      </InputLabel>
      <Select
        color={required && !value ? "error" : undefined}
        readOnly={readOnly || !list.length}
        label={CLabel}
        labelId={CLabelID}
        value={selectValue}
        onChange={onChange}
        required={required}
        variant={isInline ? "filled" : undefined}
        notched
        renderValue={(selected) => {
          const client = list.find((entry) => entry.getApiId() === selected)
          return client ? client.displayName : <em>k.A.</em>
        }}
        endAdornment={endAdornment}
      >
        {(!required || selectValue === CEmptyValueID) && (
          <MenuItem value={CEmptyValueID}>
            <em>k.A.</em>
          </MenuItem>
        )}
        {list.map((option) => (
          <MenuItem key={option.getApiId()} value={option.getApiId()}>
            {option.displayName}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{finalError}</FormHelperText>
    </FormControl>
  )
}

