import { ReactElement, useEffect, useMemo } from "react"

import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"

import { IErrors, useStore } from "core"
import { CParticipationTypeNames, CParticipationTypes, GmtProject, IProjectData } from "server/model"
import { getFilteredGameMechanics, getGameMechanicsStore } from "server/logic"
import { IInlineEditingProps, StoreDatePicker, StoreRelationSelect, StoreSimpleSelect, StoreTextField } from "../forms"
import SelectSender from "../sender/SelectSender"
import UsersAutoComplete from "../shared/UsersAutoComplete"
import SelectClient from "../client/SelectClient";

export default function ProjectDetailsPaper({
  project,
  doSave,
  errors,
}: {
  readonly project: GmtProject
  readonly doSave?: () => Promise<unknown>
  readonly errors?: IErrors<IProjectData>
}): ReactElement {
  const { baseStore, dataStore } = project
  const allMechanics = useStore(getGameMechanicsStore())
  const participationType = useStore(dataStore, (data) => data.participationType)
  const isReserved = useStore(baseStore, (data) => data.state !== "0")
  const isActive = useStore(baseStore, (data) => data.state === "2")
  const promamsValue = useStore(dataStore, ({ promamsId, promamsTitle }) =>
    promamsId && promamsTitle ? "both" : promamsId ? "id" : promamsTitle ? "title" : "none"
  )
  const client = useStore(dataStore, (data) => data.client)
  if (client) {
    dataStore.update({ clientTitle: { $set: client.dataStore.state.title } })
  }
  const gameMechanicsList = useMemo(
    () => getFilteredGameMechanics(allMechanics, participationType),
    [allMechanics, participationType]
  )

  // adjust gameMechanics property on change of list of possible game mechanics
  useEffect(() => {
    const { gameMechanics } = dataStore.state
    if (
      gameMechanics &&
      gameMechanicsList.length &&
      !gameMechanicsList.find((entry) => entry.getApiId() === gameMechanics.getApiId())
    ) {
      dataStore.update({ gameMechanics: { $set: null } })
    }
  }, [gameMechanicsList, dataStore])

  const inlineMode = typeof doSave === "function"
  const inlineProps = useMemo<IInlineEditingProps<Partial<IProjectData>>>(
    () => (inlineMode ? { baseStore, doSave } : {}),
    [inlineMode, doSave, baseStore]
  )

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h6" component="h6" sx={{ flexGrow: 1 }}>
          Details (id: {project.nid()})
        </Typography>
      </Toolbar>
      <Grid container spacing={2} paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Titel"
            store={dataStore}
            errors={errors}
            property="title"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectClient
            {...inlineProps}
            store={dataStore}
            errors={errors}
            property="client"
            required
            readOnly={isReserved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Service-ID"
            store={dataStore}
            errors={errors}
            property="sendingId"
            type="number"
            fullWidth
            required={participationType && participationType !== "online"}
            disabled={isReserved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectSender
            {...inlineProps}
            store={dataStore}
            errors={errors}
            property="sender"
            required
            readOnly={isReserved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Promams ID"
            store={dataStore}
            errors={errors}
            property="promamsId"
            fullWidth
            required={inlineMode && ["none", "id"].includes(promamsValue)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreSimpleSelect
            {...inlineProps}
            label="Teilnahmeweg"
            store={dataStore}
            errors={errors}
            property="participationType"
            list={CParticipationTypes}
            labels={CParticipationTypeNames}
            fullWidth
            required
            disabled={isReserved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Promams Titel"
            store={dataStore}
            errors={errors}
            property="promamsTitle"
            fullWidth
            required={inlineMode && ["none", "title"].includes(promamsValue)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreRelationSelect
            {...inlineProps}
            label="Spielmechanik"
            store={dataStore}
            errors={errors}
            property="gameMechanics"
            list={gameMechanicsList}
            titleKey="name"
            fullWidth
            required
            disabled={isReserved}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreTextField
            {...inlineProps}
            label="Kreditorenschlüssel"
            store={dataStore}
            errors={errors}
            property="creditorKey"
            fullWidth
            required={inlineMode}
            disabled={isActive}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UsersAutoComplete
            {...inlineProps}
            label="Projektverantwortliche"
            store={dataStore}
            property="projectManager"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StoreDatePicker
            {...inlineProps}
            label="Projekt inaktiv am"
            store={dataStore}
            errors={errors}
            property="projectInactiveOn"
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
